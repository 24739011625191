export default {
  background: '#FFF',
  primary: '#13011E',
  secondary: '#0D0014',
  tertiary: '#DB0079',
  tertiaryTrans: 'rgba(219, 0, 121, 0.15)',
  quarternary: '#E7D2DE',
  text: {
    light: '#FFF',
    medium: '#4A5668',
    dark: '#13011D',
  },
  muted: {
    lightest: '#F5F5F5',
    light: '#EEE',
    medium: '#CFD5DD',
    dark: '#B5BDCA',
    darkest: '#2B313C',
  },
  label: ['#20C086', '#F5A623', '#E7D2DE'],
  stimRed: '#E33247',
};
