export default {
  root: {
    backgroundColor: 'background',
    color: 'text.dark',
    minWidth: 'minWidth',
    fontFamily: 'Roboto',
    scrollBehavior: 'smooth',
    textRendering: 'geometricPrecision',
  },
};
