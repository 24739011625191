import { Theme } from 'theme-ui';

import breakpoints from './breakpoints';
import colors from './colors';
import fontSizes from './fontSizes';
import sizes from './sizes';
import space from './space';
import styles from './styles';

export default {
  breakpoints,
  colors,
  fontSizes,
  sizes,
  space,
  styles,
} as Theme;
